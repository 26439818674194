<!-- (c) 才酝通 -->
<template>
    <div class="box">
        <nav-header :title="header_title"></nav-header>
        <div class="body">
            <div class="coursr-desc">
                <div class="u-content md-content">
                    <div
                        class="desc"
                        v-html="
                        content.replace(
                            /<img/g,
                            '<img style=&quot;width:100%;height:auto;&quot;'
                        )
                        "
                        style="font-size: 16px"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import NavHeader from "../../components/nav-header.vue";

export default {
    components: {
        NavHeader
    },
    computed: {
        ...mapState(["config"]),
    },
    data() {
        return {
            type: this.$route.query.type,
            header_title: '',
            content: ''
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            if (this.type == 'user_protocol') {
                this.header_title = '用户服务协议';
                this.content = this.config.userProtocol;
            }else if(this.type == 'user_private_protocol') {
                this.header_title = '用户隐私协议';
                this.content = this.config.userPrivateProtocol;
            }else {
                this.header_title = '关于我们';
                this.content = this.config.aboutUs;
            }
        }
    }
}
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  overflow: hidden;
}
.topic-title {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px 0 15px;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    line-height: 30px;
    margin-top: 15px;
    margin-bottom: 10px;
}
.dec{
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: flex;
    padding: 0px 15px 0 15px;
    font-size: 12px;
    font-weight: 400;
    color: #999;
    .view-count {
        margin-left: 20px;
    }
}
.coursr-desc {
  width: 100%;
  height: auto;
  float: left;
  color: #333333;
  line-height: 21px;
  box-sizing: border-box;
  padding: 20px 15px 50px 15px;
}
.coursr-desc {
  .desc {
    width: 100%;
  }
}
</style>
